/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import styled from 'styled-components';

import Button from '@/components/Button/Button';
import { Container } from '@/components/Container';
import Section from '@/components/Section/Section';
import { QuickbooksLogo } from '@/public/icons';
import bg from '@/public/Quickbooks.webp';
import { backgroundImageMixin } from '@/styles/BackgroundimageMixin';
import { devices } from '@/styles/breakpoints';
import { RegularText } from '@/styles/typography';

export const QuickbooksBlock = () => {
  return (
    <Layout>
      <Container>
        <Section>
          <ContainerInner>
            <LogoContainer>
              <QuickbooksLogo />
              <Tag>Tools integration</Tag>
            </LogoContainer>
            <SubTitle>Integration That Helps You Work</SubTitle>
            <Text>
              Orcatec's integration with QuickBooks Online streamlines your
              accounting processes, allowing you to effortlessly sync
              transactions, invoices, and payments between platforms. Spend less
              time on manual data entry and more time growing your business with
              accurate, up-to-date financial insights.
            </Text>
            <ButtonWrapper>
              <Button
                onClick={() =>
                  window.open('https://app.orcatec.com/auth/signup', 'e_blank')
                }
                fullWidth
                className="outlined"
              >
                Get Started Free
              </Button>
            </ButtonWrapper>
          </ContainerInner>
        </Section>
      </Container>
    </Layout>
  );
};

const Layout = styled.div`
  position: relative;
  ${backgroundImageMixin(bg.src)}
`;
const ContainerInner = styled.div`
  padding: 12px 0;
  @media only screen and ${devices.desktop} {
    padding: 40px 0;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 26px;
`;

const Tag = styled.div`
  background: rgba(26, 178, 226, 1);
  border-radius: 4px;
  padding: 4px 8px;
  color: white;
  font-weight: 700;
  line-height: 16px;
  transform: rotate(-4deg);
  font-size: 12.3px;
`;

const SubTitle = styled.p`
  font-family: var(--font-roboto);
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  margin-bottom: 12px;
  color: white;
  font-weight: 600;
  padding: 0 10px;
  @media only screen and ${devices.desktop} {
    font-size: 40px;
    line-height: 46px;
    margin-bottom: 16px;
  }
`;

const Text = styled(RegularText)`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 12px;
  color: white;
  font-weight: 400;
  margin-bottom: 24px;
  @media only screen and ${devices.desktop} {
    font-size: 14px;
    line-height: 20px;
    max-width: 740px;
    margin: 0 auto 24px;
  }
`;

const ButtonWrapper = styled.div`
  @media only screen and ${devices.desktop} {
    max-width: 165px;
    margin: 0 auto;
  }
`;
