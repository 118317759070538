import React, { useRef } from 'react';
import Image from 'next/image';
import styled from 'styled-components';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import Button from '@/components/Button/Button';
import { useNewsletterModalContext } from '@/context/newsletter-modal.context';
import { devices } from '@/styles/breakpoints';
import { RegularText } from '@/styles/typography';

const Card = ({ item, onOpenModal }) => {
  return (
    <CardWrapper>
      <CardImage>
        <ImageWrapper>
          <Image src={item.image} alt="alt" />
        </ImageWrapper>
      </CardImage>

      <CardContent>
        <CardTitle>{item.title}</CardTitle>
        <CardDescription>{item.description}</CardDescription>
        <ButtonWrapper>
          {' '}
          <Button onClick={onOpenModal} fullWidth className="secondary">
            Contact Us
          </Button>
        </ButtonWrapper>
      </CardContent>
    </CardWrapper>
  );
};

export const DevelopmentBlock = ({ items }) => {
  const sliderRef = useRef(null);
  const { setIsModalOpened } = useNewsletterModalContext();

  return (
    <Wrapper>
      <MobileWrapper>
        <Swiper
          ref={sliderRef}
          slidesPerView={1.3}
          pagination={true}
          spaceBetween={24}
          navigation={true}
          effect="fade"
          loop={false}
          modules={[Autoplay, Navigation, Pagination]}
          className="developer-swiper"
          breakpoints={{
            640: {
              slidesPerView: 1.3,
            },
            768: {
              slidesPerView: 1.3,
            },
            1024: {
              slidesPerView: 2,
            },
          }}
        >
          {items.map((item, idx) => (
            <SwiperSlide key={idx}>
              <Card item={item} onOpenModal={() => setIsModalOpened(true)} />
            </SwiperSlide>
          ))}
        </Swiper>
      </MobileWrapper>
      <DesktopWrapper>
        {items.map((item, idx) => (
          <Card
            item={item}
            key={idx}
            onOpenModal={() => setIsModalOpened(true)}
          />
        ))}
      </DesktopWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const MobileWrapper = styled.div`
  & .swiper-pagination {
    bottom: -4px;
  }
  & .developer-swiper {
    padding-bottom: 30px;
  }

  @media only screen and ${devices.desktop} {
    display: none;
  }
`;
const DesktopWrapper = styled.div`
  display: none;
  @media only screen and ${devices.desktop} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
  }
`;

const CardWrapper = styled.div`
  padding: 16px;
  border: 1px solid rgba(219, 224, 239, 1);
  border-radius: 10px;
  width: 100%;
  max-width: 280px;

  @media only screen and ${devices.desktop} {
    max-width: 100%;
    padding: 24px;
    display: flex;
    gap: 16px;
  }
`;

const ImageWrapper = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 6px;
  overflow: hidden;

  & img {
    width: 100%;
    height: 100%;
  }
`;

const CardImage = styled.div`
  margin-bottom: 16px;
  /* & img {
    width: 80px;
    height: 80px;
  } */
`;

const CardContent = styled.div``;

const CardTitle = styled(RegularText)`
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 12px;
  @media only screen and ${devices.desktop} {
    margin-bottom: 8px;
  }
`;
const CardDescription = styled(RegularText)`
  margin-bottom: 16px;
  @media only screen and ${devices.desktop} {
    margin-bottom: 24px;
  }
`;

const ButtonWrapper = styled.div`
  @media only screen and ${devices.desktop} {
    max-width: 120px;
  }
`;
