import React from 'react';
import styled from 'styled-components';

import { devices } from '@/styles/breakpoints';
export const AdditionalPaddings = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled.div`
  padding: 20px 0px;
  @media only screen and ${devices.desktop} {
    padding: 40px 0;
  }
`;
