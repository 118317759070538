'use client';

import React, { FC } from 'react';
import Image from 'next/image';
import styled from 'styled-components';

import { DotIcon } from '@/public/icons';
import { devices } from '@/styles/breakpoints';
import { RegularText, TitleH2 } from '@/styles/typography';

interface IProps {
  title: string;
  subTitle: string;
  images: { src: string; alt: string }[];
  awards: object[];
}

function isOdd(number) {
  return number % 2 !== 0;
}

const ItemBlock = ({ item, position }) => {
  const isLeft = isOdd(position + 1);

  return (
    <Item $isLeft={isLeft} className="item">
      <ItemContent>
        <ItemContentDescription $isLeft={isLeft}>
          {item.articleText}
        </ItemContentDescription>
      </ItemContent>
      <ItemDivider>
        <DividerTitle>{item.sectionTitle}</DividerTitle>
        <DividerLine>
          <DividerDot className={`top dot-${position}`}>
            <DotIcon />
          </DividerDot>

          <DividerDot className={`bottom dot-${position + 1}`}>
            <DotIcon />
          </DividerDot>
        </DividerLine>
      </ItemDivider>
      <ItemImages $isLeft={isLeft}>
        {item.images.map(({ image }, idx) => (
          <div
            key={idx}
            style={{
              width: '84px',
            }}
          >
            <Image src={image} alt={idx} />
          </div>
        ))}
      </ItemImages>
    </Item>
  );
};

const ItemBlockMobile = ({ item }) => {
  return (
    <ItemMobile>
      <TitleMobile>{item.sectionTitle}</TitleMobile>
      <Description>{item.articleText}</Description>
      <ItemListMobile>
        {item?.images?.map(({ image }, idx) => (
          <div className="mobile-image" key={idx}>
            <Image src={image} alt={idx} />
          </div>
        ))}
      </ItemListMobile>
    </ItemMobile>
  );
};

export const Awards: FC<IProps> = ({ awardsV2 }) => {
  return (
    <Wrapper>
      <div className="wrap">
        <div className="items-wrap">
          <div className="items marquee desktop">
            {awardsV2?.map((item, idx) => (
              <ItemBlock key={idx} position={idx} second={false} item={item} />
            ))}
          </div>
          <WrappeColumn>
            <div className="line"></div>
            <DividerDot className={`mob dot-${1}`}>
              <DotIcon />
            </DividerDot>
            <DividerDot className={`mob dot-${2}`}>
              <DotIcon />
            </DividerDot>
            <DividerDot className={`mob dot-${3}`}>
              <DotIcon />
            </DividerDot>
          </WrappeColumn>
          <div className="items marquee mobile">
            {awardsV2?.map((item, idx) => (
              <ItemBlockMobile key={idx} item={item} />
            ))}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.article`
  padding-top: 40px;
  & .items.desktop {
    display: none;
    @media only screen and ${devices.desktop} {
      display: block;
    }
  }
  & .items.mobile {
    & .mobile-image {
      width: 52px;
      height: 58px;
      flex-shrink: 0;
      text-align: center;
      display: flex;
      align-items: center;
      & img {
        width: 100%;
        height: auto;
      }
    }
    @media only screen and ${devices.desktop} {
      display: none;
    }
  }
  .wrap {
    max-width: 100%;
  }
  & .line {
    width: 1px;
    height: 100%;
    background: #d0def6;
  }
  .items-wrap {
    display: grid;
    grid-template-columns: 20px 1fr;
    gap: 16px;
    @media only screen and ${devices.desktop} {
      display: flex;
      position: relative;
      width: 100%;
      overflow: hidden;
      user-select: none;
      gap: 20px;
    }
  }

  .items {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-around;
  }

  .item {
    transition: all 0.1s ease-in-out;
  }
`;
const WrappeColumn = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  @media only screen and ${devices.desktop} {
    display: none;
  }
  & .mob.dot-1 {
    top: -8px;
    left: 2px;
    & svg {
      width: 16px;
      height: 16px;
    }
  }
  & .mob.dot-2 {
    top: 50%;
    left: 2px;
    & svg {
      width: 16px;
      height: 16px;
    }
  }
  & .mob.dot-3 {
    top: unset;
    bottom: -8px;
    left: 2px;
    & svg {
      width: 16px;
      height: 16px;
    }
  }
`;

const TitleMobile = styled(TitleH2)`
  margin-bottom: 8px;
`;
const Description = styled(RegularText)`
  margin-bottom: 24px;
`;

const Item = styled.article<{ $isLeft: boolean }>`
  display: grid;
  grid-template-columns: 1fr 128px 1fr;
  width: 100%;
  min-height: 224px;
  direction: ${(props) => (props.$isLeft ? '' : 'rtl')};
  align-items: center;
`;
const ItemContent = styled.div`
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 24px;
`;
const ItemImages = styled.div<{ $isLeft: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  align-content: flex-start;
  flex-direction: ${(props) => (props.$isLeft ? 'row' : '')};
  gap: 24px;
  flex-wrap: wrap;
  padding-top: 46px;
  & img {
    width: 100%;
    height: auto;
  }
`;

const ItemDivider = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 128px;
`;

const ItemContentDescription = styled(RegularText)`
  /* text-align: ${(props) => (!props.isLeft ? 'right' : 'left')}; */
  text-align: left;
  direction: ltr;
  font-size: 16px;
`;

const DividerTitle = styled(RegularText)`
  color: #192d52;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 46px;
  margin-bottom: 16px;
`;

const DividerLine = styled.div`
  position: relative;
  width: 1px;
  height: 350px;
  background-color: #d0def6;
  margin: 32px 0;
`;

const DividerDot = styled.div`
  position: absolute;
  top: -8px;
  left: -8px;
  /* &.top.dot-1 {
    top: -8px;
    left: -8px;
    & svg {
      width: 16px;
      height: 16px;
    }
  } */
  &.bottom.dot-2 {
    top: unset;
    bottom: -5px;
    left: -8px;
    & svg {
      width: 16px;
      height: 16px;
    }
  }
`;

const ItemMobile = styled.div``;
const ItemListMobile = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 14.78px;
  flex-wrap: wrap;
`;
