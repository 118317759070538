import awards1_2023 from '@/public/awards/2023/2023_1.webp';
import awards2_2023 from '@/public/awards/2023/2023-2.webp';
import awards3_2023 from '@/public/awards/2023/2023-3.webp';
import awards4_2023 from '@/public/awards/2023/2023-4.webp';
import awards5_2023 from '@/public/awards/2023/2023-5.webp';
import awards6_2023 from '@/public/awards/2023/2023-6.webp';
import awards1_2024 from '@/public/awards/2024/2024_1.webp';
import awards2_2024 from '@/public/awards/2024/2024_2.webp';
import awards3_2024 from '@/public/awards/2024/2024_3.webp';
import awards4_2024 from '@/public/awards/2024/2024_4.webp';
import awards5_2024 from '@/public/awards/2024/2024_5.webp';
import awards6_2024 from '@/public/awards/2024/2024_6.webp';
import awards1 from '@/public/awards/top-performer-winter-white-_1_-_1__1.webp';
import dev from '@/public/development/dev.webp';
import dispatcher from '@/public/development/dispatcher.webp';
import {
  CalculatorIcon,
  CalendarIcon,
  CardIcon,
  ClockTimeIcon,
  ComputerIcon,
  DocumentIcon,
  EmailIcon,
  GroupIcon,
  QuickBooksIcon,
  ScheduleIcon,
  StarsBlueIcon,
  WalletIcon,
} from '@/public/icons';
import AirloopImage from '@/public/partners/Airloop_heating_air.webp';
import GarantImage from '@/public/partners/Appliance_servicenter.webp';
import BuildxImage from '@/public/partners/BuildX.webp';
// import RemovalImage from '@/public/partners/RemovalPro.webp';
import RemovalImage from '@/public/partners/logo-removal-pro.webp';
import SNImage from '@/public/partners/Safety_Net_logo.webp';
import accounting from '@/public/potential/Accounting.webp';
import payment from '@/public/potential/Credit card processing.webp';
import dispatch from '@/public/potential/Dispatch.webp';
import emails from '@/public/potential/Emails.webp';
import estimate from '@/public/potential/Estimate.webp';
import fastPayment from '@/public/potential/FastPayment.webp';
import reviewsImg from '@/public/potential/Reviews.webp';
import route from '@/public/potential/Route-Optimization.webp';
import schedule from '@/public/potential/Schedule.webp';
import timecards from '@/public/potential/Timecard.webp';
import review1 from '@/public/reviews/CapterraSvg.svg';
import review2 from '@/public/reviews/GetAppSvg.svg';
import review4 from '@/public/reviews/Incognito.svg';
import review3 from '@/public/reviews/SoftwareAdvice.svg';
import applianceImage from '@/public/services/appliance_service.webp';
import cleaningImage from '@/public/services/cleaning_service.webp';
import electricalImage from '@/public/services/electrical_service.webp';
import garageImage from '@/public/services/garage_doors_service.webp';
import hvacImage from '@/public/services/hvac_service.webp';
import junkImage from '@/public/services/junk_remoal_service.webp';
import locksmithImage from '@/public/services/locksmith_service.webp';
import otherImage from '@/public/services/other_service.webp';
import plumbingImage from '@/public/services/plumbing_service.webp';
import propertyImage from '@/public/services/property_maintenance_service.webp';
import Socall from '@/public/socall.svg';
import { IRoutePath } from '@/types/router';

const faq = {
  title: 'FAQ',
  items: [
    {
      title: 'What is home service management?',
      text: 'Home service management is the comprehensive coordination of all aspects of providing residential services, such as scheduling, dispatching, invoicing, and customer relationship management. Orcatec elevates this process with powerful, intuitive tools that streamline every facet, enhancing efficiency and productivity for service providers.',
    },
    {
      title: `How can Orcatec's field service management software help my business?`,
      text: `Orcatec's field service management software can revolutionize your business operations. It simplifies complex scheduling, streamlines communication, and ensures seamless invoicing. By automating these essential tasks, Orcatec helps you focus on delivering top-notch services while growing your customer base and improving satisfaction rates.`,
    },
    {
      title: `What are the benefits of using Orcatec's home service management software?`,
      text: `Utilizing Orcatec's free home service management software brings multiple benefits, including significant time savings through automation, minimizing human error, and enhancing customer satisfaction with consistent and reliable service. Our platform gives you a comprehensive overview of your business activities, enabling better strategic decisions that lead to increased efficiency and profitability.`,
    },
    {
      title: `Why choose Orcatec for software for home service management?`,
      text: `Choosing Orcatec for your home service company software needs means selecting a partner dedicated to your success. Our tailored solutions cater specifically to contractors, focusing on optimized resource management, streamlined operations, and excellent customer service. Orcatec is more than just software; it's a vital tool to ensure your business thrives in the competitive home services market.`,
    },
  ],
};

const testimonals = {
  title: 'Client’s Testimonials',
  description:
    'At Orcatec, we believe that the true measure of our success is reflected in the satisfaction and achievements of our clients. These reviews from diverse field service businesses demonstrate the transformative impact Orcatec can have on companies striving for excellence.',
  items: [
    {
      text: 'The integration of Orcatec into our daily operations has resulted in a notable increase in customer satisfaction and repeat business.',
      name: 'Kelly,',
      position: 'VoltEdge Electrical Solutions',
    },
    {
      text: 'Our administrative workload has halved since we started using Orcatec, allowing us to focus more on fieldwork and less on paperwork.',
      name: 'Alex,',
      position: 'Apex Garage Door Services',
    },
    {
      text: `Choosing Orcatec was a pivotal point for our business. Their intuitive tools and responsive support have been instrumental in our growth.`,
      name: 'Mark,',
      position: 'KeyGuard Locksmith Network',
    },
    {
      text: 'The integration of Orcatec into our daily operations has resulted in a notable increase in customer satisfaction and repeat business.',
      name: 'Kelly,',
      position: 'VoltEdge Electrical Solutions',
    },
    {
      text: 'Our administrative workload has halved since we started using Orcatec, allowing us to focus more on fieldwork and less on paperwork.',
      name: 'Alex,',
      position: 'Apex Garage Door Services',
    },
    {
      text: `Choosing Orcatec was a pivotal point for our business. Their intuitive tools and responsive support have been instrumental in our growth.`,
      name: 'Mark,',
      position: 'KeyGuard Locksmith Network',
    },
  ],
};

const reviews = {
  title: 'Our Reviews',
  items: [
    { path: review1.src, width: review1.width, alt: 'Capterra' },
    { path: review2.src, width: review2.width, alt: 'GetApp' },
    { path: review3.src, width: review3.width, alt: 'Software Advice' },
    { path: review4.src, width: review4.width, alt: 'Software Advice' },
  ],
};

const services = {
  title: 'Made for Field Service Businesses',
  description:
    'Orcatec is the most suitable solution for field service businesses looking to streamline their operations. Our platform is customized to support a variety of industries, ensuring that whatever your specialty, you have the tools needed to schedule jobs, optimize routes, invoice customers, and get paid – all in one place for your up-to-date home service management system.',
  items: [
    {
      title: 'HVAC',
      path: IRoutePath.INDUSTRIES_HVAC,
      linkTitle: 'Learn More',
      description:
        'Secure more HVAC jobs all year round with service plans, sales proposals, price books, and more',
      image: {
        src: hvacImage.src,
        alt: 'hvac image',
      },
    },
    {
      title: 'Garage Doors',
      path: IRoutePath.INDUSTRIES_GARAGE_DOOR,
      linkTitle: 'Learn More',
      image: {
        src: garageImage.src,
        alt: 'Garage Doors image',
      },
      description:
        'Boost your garage door business by securing more projects and enhancing customer loyalty through service plans, sales proposals, and additional strategies.',
    },
    {
      title: 'Plumbing',
      path: IRoutePath.INDUSTRIES_PLUMBING,
      linkTitle: 'Learn More',
      description:
        'Win more plumbing jobs and close bigger deals with online booking and sales proposals all in one place',
      image: {
        src: plumbingImage.src,
        alt: 'Plumbing image',
      },
    },
    {
      title: 'Property Maintenance',
      path: IRoutePath.INDUSTRIES,
      linkTitle: 'Learn More',
      image: {
        src: propertyImage.src,
        alt: 'Property Maintenance image',
      },
      description:
        'Stay on top of property maintenance tasks effortlessly. Orcatec offers tools for scheduling, invoicing, and customer support.',
    },
    {
      title: 'Electrical',
      path: IRoutePath.INDUSTRIES_ELECTRICIAN,
      linkTitle: 'Learn More',
      image: {
        src: electricalImage.src,
        alt: 'Electrical image',
      },
      description:
        'Organize your electrician business thoroughly, boost efficiency, and improve communication flows',
    },
    {
      title: 'Locksmith',
      path: IRoutePath.INDUSTRIES,
      linkTitle: 'Learn More',
      description:
        'Make your locksmith business more efficient with online booking, quick dispatching, and scheduling',
      image: {
        src: locksmithImage.src,
        alt: 'Locksmith image',
      },
    },
    {
      title: 'Appliance Repair',
      path: IRoutePath.INDUSTRIES_APPLIANCE,
      linkTitle: 'Learn More',
      image: {
        src: applianceImage.src,
        alt: 'Appliance Repair',
      },
      description:
        'Optimize the operation of your appliance repair business through streamlined inventory management and convenient online booking systems',
    },

    {
      title: 'Junk Removal',
      path: IRoutePath.INDUSTRIES,
      linkTitle: 'Learn More',
      image: {
        src: junkImage.src,
        alt: 'Junk Removal image',
      },
      description:
        'Become the leading junk removal company by utilizing a comprehensive suite of tools designed to enhance and expand your business operations',
    },
    {
      title: 'Home Cleaning',
      path: IRoutePath.INDUSTRIES,
      linkTitle: 'Learn More',
      image: {
        src: cleaningImage.src,
        alt: 'Home Cleaning image',
      },
      description: `Elevate your home cleaning services with Orcatec's platform, offering easy appointment scheduling and customer communication.`,
    },

    {
      title: 'Other Industries',
      path: IRoutePath.INDUSTRIES,
      linkTitle: 'More Industries',
      image: {
        src: otherImage.src,
        alt: 'Other Industries image',
      },
      description: `No matter your industry, we have all the tools you need to run your business like a pro.`,
    },
  ],
};

const potential = {
  title: 'Manage Your Business Easily',
  description:
    'Easily set up appointments with your clients at work time and block time offs or vacation hours/days. Handle appointments with color coding and a simple drag-and-drop interface',
  items: [
    {
      tag: 'Dispatch',
      title: 'Easily set up appointments with your clients',
      description:
        'Easily set up appointments with your clients at work time and block time offs or vacation hours/days. Handle appointments with color coding and a simple drag-and-drop interface.',
      icon: CalendarIcon,
      image: dispatch,
    },
    {
      tag: 'Schedule',
      title: `Efficiently plan your team's tasks and optimize routes using AI`,
      description: `Efficiently manage your team's workload and appointments with our intuitive scheduling tool. Keep track of upcoming tasks and allocate resources effectively to maximize productivity.`,
      icon: ScheduleIcon,
      image: schedule,
    },
    {
      tag: 'Route Optimization',
      title: `Keep track of your employees' work hours`,
      description:
        'Minimize the windshield time and maximize the profit with Orcatec AI Route Optimizer. AI will suggest the best person, date, and time for an appointment based on your preferred settings.',
      icon: ClockTimeIcon,
      image: route,
    },
    {
      tag: 'Accounting',
      title: 'Evaluate your current and future profits ',
      description:
        'Streamline your financial processes with our integrated accounting features. Easily track expenses, invoices, and payments, ensuring accurate financial records and efficient cash flow management.',
      icon: CalculatorIcon,
      image: accounting,
    },

    {
      tag: 'Email campaign',
      title: 'Boost your sales using effective marketing tools',
      description:
        'Reach out to your customers and prospects with targeted email campaigns. Create personalized messages, track engagement metrics, and nurture leads to drive conversions and grow your business.',
      icon: EmailIcon,
      image: emails,
    },
    {
      tag: 'Estimates',
      title: 'Generate field proposals',
      description:
        'Generate and track your field proposals. Track estimate views by your client. Get instant notification when client opens your estimate. Increase your profit with Good, Better, Best models.',
      icon: DocumentIcon,
      image: estimate,
    },
    {
      tag: 'Reviews',
      title: 'Receive reviews and feedback from your clients',
      description:
        'Collect and manage customer reviews to build trust and credibility for your business. Encourage satisfied customers to leave positive feedback and address any concerns promptly to maintain a stellar reputation.',
      icon: StarsBlueIcon,
      image: reviewsImg,
    },

    {
      tag: 'Fast-Payment',
      title:
        'Process credit card transactions directly in the mobile app with the lowest industry fees ',
      description:
        'Expedite your payment process with our fast-payment feature. Send invoices electronically and enable quick and secure online payments, reducing payment delays and improving cash flow.',
      icon: CardIcon,
      image: fastPayment,
    },
    {
      tag: 'Time cards',
      title: `Keep track of your employees' work hours`,
      description:
        'Track time worked by your employees and GPS location of an employee while clocked in. Export the data to a spreadsheet. Automatic clock-out options.',
      icon: ClockTimeIcon,
      image: timecards,
    },

    {
      tag: 'Credit card processing',
      title: `Keep track of your employees' work hours`,
      description:
        'Process payments by accepting credit cards with very low processing fees. Use mobile credit card terminals or send an invoice for payment. Get instant notification if the invoice has been paid.',
      icon: ClockTimeIcon,
      image: payment,
    },
  ],
};

const whatIs = {
  title: 'What is Orcatec?',
  subTitle:
    'Orcatec is your all-in-one home services management software designed to save you time, money, and boost productivity. It organizes all your tasks, synchronizes the work of your team, technicians, and clients.',
  items: [
    {
      title: '60% Times Saved on Reporting, Invoicing & Planning',
      icon: CardIcon,
    },
    { title: '120% Return on Investments on the First Year', icon: WalletIcon },
    {
      title: 'Optimized Time Management & Scheduling To Get In Time',
      icon: CalendarIcon,
    },
    { title: 'Quickbooks Automatic Synchronization', icon: QuickBooksIcon },
    { title: 'Customize Online Platform To Your Field', icon: ComputerIcon },
    { title: 'Easy-To-Use Cross-Platform Software', icon: GroupIcon },
  ],
};

const awards = {
  title: 'Success Recognized By A Collection Of Awards.',
  subTitle: `Orcatec takes pride in its numerous awards, a testament to 
     our commitment to innovation and excellence in field business software.`,
  images: [
    { src: awards1.src, alt: '1' },
    { src: awards1.src, alt: '2' },
    { src: awards1.src, alt: '3' },
    { src: awards1.src, alt: '4' },
    { src: awards1.src, alt: '5' },
  ],
};

const awards2 = [
  {
    sectionTitle: '2024',
    articleText:
      'In 2024, we continued to receive accolades, highlighting our ongoing commitment to improvement and our focus on meeting the needs of our customers. This recognition reflects our ability to adapt to changes, providing cutting-edge solutions and unparalleled service. ',
    images: [
      { image: awards1_2024 },
      { image: awards2_2024 },
      { image: awards3_2024 },
      { image: awards4_2024 },
      { image: awards5_2024 },
      { image: awards6_2024 },
    ],
  },
  {
    sectionTitle: '2023',
    articleText:
      '2023 was a year of recognition for Orcatec, as we earned prestigious awards, showcasing our commitment to excellence and innovation. These accolades affirmed our unwavering dedication to delivering a high-quality product and exceptional customer service.',
    images: [
      { image: awards1_2023 },
      { image: awards2_2023 },
      { image: awards3_2023 },
      { image: awards4_2023 },
      { image: awards5_2023 },
      { image: awards6_2023 },
    ],
  },
];

const blog = {
  title: 'Our Blog',
  description:
    'Discover strategies and stories that will inspire your next business breakthrough. Visit the Orcatec blog today and start transforming your operational excellence into tangible results.',
};

const bookDemo = {
  title: 'Book a Free Demo',
  description: `Book a free demo to unlock the full potential of our software for HVAC contractors! Orcatec team is here to guide you. Fill out the form below to receive expert consultation. Don’t miss this opportunity to transform your HVAC business with Orcatec's cutting-edge solutions, specially designed for HVAC contractors!`,
};

const partners = {
  items: [
    {
      image: GarantImage,
      alt: ' appliance servicecenter company',
    },
    {
      image: RemovalImage,
      alt: 'removal pro company',
    },
    {
      image: AirloopImage,
      alt: 'air loop company',
    },
    {
      image: BuildxImage,
      alt: 'buildx company',
    },
    {
      image: Socall,
      alt: 'socall',
    },
    {
      image: SNImage,
      alt: 'sn',
    },
  ],
};

const developFeatures = [
  {
    title: 'Custom Development',
    description: `With our development team, you can create software that perfectly aligns with your unique needs. If specific features and functionality are essential for your business success, we're here to code them for you. You pay for the functionality you want to design, and we handle the maintenance costs.`,
    image: dev,
  },
  {
    title: 'Remote Dispatcher',
    description: `We know every business is unique and want our system to fit your needs perfectly. Tailor every aspect to your preferences by choosing the functionalities that matter most to you and consider taking advantage of our custom development options for a solution designed exclusively for you.`,
    image: dispatcher,
  },
];

export {
  awards,
  whatIs,
  potential,
  services,
  reviews,
  testimonals,
  faq,
  blog,
  bookDemo,
  partners,
  awards2,
  developFeatures,
};
