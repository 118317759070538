'use client';

import React, { FC } from 'react';
import Image from 'next/image';
import styled from 'styled-components';

import image from '../../public/Image-main_2.webp';
import background from '../../public/main_background.webp';

import Button from '@/components/Button/Button';
import { Container } from '@/components/Container';
import { backgroundImageMixin } from '@/styles/BackgroundimageMixin';
import { devices } from '@/styles/breakpoints';
import { TitleH2 } from '@/styles/typography';

interface IProps {}

export const MainIntro: FC<IProps> = () => {
  return (
    <SectionWrapper>
      <Overlay>
        <Container>
          <WrapperInner>
            <LabelWrapper>
              <h1>Home Services Management Software</h1>
            </LabelWrapper>

            <Title>
              <span className="first_part">
                All-In-One Home <br /> Services Management{' '}
              </span>
              <span className="second_part">Software</span>
            </Title>

            <Description>
              Empower your business with the most reliable home service software
              solution. Our all-in-one platform, used by professionals in over
              40 service industries, can be your go-to tool for effective home
              service management. Schedule services, dispatch teams, handle
              invoicing, and track performance seamlessly - with Orcatec,
              success is just a click away.
            </Description>
            <Button
              onClick={() =>
                window.open('https://app.orcatec.com/auth/signup', 'e_blank')
              }
              variant="secondary"
            >
              Get Started Free
            </Button>
          </WrapperInner>
        </Container>

        <ImageWrapper>
          <Image priority={true} src={image} alt="background image" />
        </ImageWrapper>
      </Overlay>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.section`
  position: relative;
  width: 100%;
  height: 608px;
  ${backgroundImageMixin(background.src)};

  @media only screen and ${devices.desktop} {
    height: 834px;
  }
`;

const LabelWrapper = styled.div`
  color: var(--fontColorDarkBlue);
  text-align: left;
  font-family: var(--font-roboto);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 8px;
  @media only screen and ${devices.desktop} {
    text-align: center;
  }
`;

const Overlay = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ImageWrapper = styled.div`
  /* position: absolute;
  bottom: 0; */
  overflow: hidden;
  height: auto;
  /* min-height: 180px; */
  width: 100%;
  & img {
    display: block;
    height: auto;
    width: 100%;
  }

  @media only screen and ${devices.desktop} {
    & img {
      height: 380px;
      width: 1128px;
      margin: 0 auto;
    }
  }
`;

const WrapperInner = styled.article`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  margin: 0 auto;
  padding-top: 30px;
  & button {
    width: 100% !important;
  }
  @media only screen and ${devices.desktop} {
    padding-top: 62px;
    align-items: center;
    max-width: 720px;
    & button {
      width: 165px !important;
      padding: 12px 24px;
    }
  }
`;

const Title = styled(TitleH2)`
  text-align: left;
  margin-bottom: 16px;
  font-size: 32px;
  & .first_part {
    color: var(--darkBlueText);
  }
  & .second_part {
    color: var(--mainBlue);
  }

  @media only screen and ${devices.desktop} {
    line-height: 46px;
    text-align: center;
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 58px;
  }
`;

const Description = styled.p`
  font-family: var(--font-roboto);
  color: var(--fontColorDarkGrey);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 32px;
  @media only screen and ${devices.desktop} {
    text-align: center;
  }
`;
