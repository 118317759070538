import React, { FC } from 'react';
import Image from 'next/image';
import styled from 'styled-components';

import { devices } from '@/styles/breakpoints';

interface IProps {
  items: { image: string; alt: string }[];
}

const Item = ({ item }) => {
  return (
    <ListItem>
      <Image
        width={(item.alt === 'removal pro company' || item.alt === 'sn') && 120}
        height={(item.alt === 'removal pro company' || item.alt === 'sn') && 60}
        src={item.image}
        alt={item.alt}
      />
    </ListItem>
  );
};

export const Partners: FC<IProps> = ({ items }) => {
  return (
    <ListItems>
      {items.map((item, idx) => (
        <Item key={idx} item={item} />
      ))}
    </ListItems>
  );
};

const ListItems = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  border-radius: 10px;
  background: #f6f7f9;
  padding: 24px 0px;

  @media only screen and ${devices.desktop} {
    padding: 40px;
    flex-direction: row;
    justify-content: space-evenly;
  }
`;
const ListItem = styled.li`
  position: relative;
  padding: 0;
`;
