import React from 'react';
import styled from 'styled-components';

import image from '@/public/awards_layout.webp';
import { backgroundImageMixin } from '@/styles/BackgroundimageMixin';
import { devices } from '@/styles/breakpoints';

export const AwardsLayout = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #f6f7f9;
  ${backgroundImageMixin(image.src)}
  @media only screen and ${devices.desktop} {
    padding-top: 80px;
  }
`;
