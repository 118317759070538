import React, { FC } from 'react';
import Image from 'next/image';
import styled from 'styled-components';

import { StarsIcons } from '@/public/icons';
import { devices } from '@/styles/breakpoints';
import { TitleH2 } from '@/styles/typography';

interface IProps {
  item: object;
}

export const OurReviews: FC<IProps> = ({ item }) => {
  return (
    <Wrapper>
      <Title>{item.title}</Title>
      <ListItems>
        {item.items.map((item, idx, arr) => (
          <React.Fragment key={idx}>
            <Item>
              <Image
                width={item.width}
                height={24}
                src={item.path}
                alt={item.alt}
              />
              <StarsIcons />
            </Item>
            {idx !== arr.length - 1 && (
              <li>
                <Divider />
              </li>
            )}
          </React.Fragment>
        ))}
      </ListItems>
    </Wrapper>
  );
};

const Wrapper = styled.article`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
  background: #f6f7f9;
  padding: 20px;

  @media only screen and ${devices.desktop} {
    flex-direction: row;
    justify-content: space-between;
    padding: 40px;
  }
`;

const Title = styled(TitleH2)`
  margin-bottom: 24px;
  @media only screen and ${devices.desktop} {
    margin-bottom: 0;
  }
`;

const ListItems = styled.ul`
  display: flex;
  align-items: center;
  row-gap: 16px;
  flex-direction: column;
  padding: 0;
  list-style: none;
  @media only screen and ${devices.desktop} {
    flex-direction: row;
  }
`;

const Item = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

const Divider = styled.div`
  @media only screen and ${devices.desktop} {
    margin: 0 40px;
    width: 1px;
    height: 100%;
    background-color: rgba(214, 219, 237, 1);
    margin: 0 40px;
    height: 52px;
  }
`;
