import React, { FC, useState } from 'react';
import Image from 'next/image';
import styled from 'styled-components';

import fasad from '@/public/video_bg.webp';
import { devices } from '@/styles/breakpoints';
import { TitleH2 } from '@/styles/typography';
import { RegularText } from '@/styles/typography';

interface IProps {
  title: string;
  subTitle: string;
  items: [title: string, icon: string];
  revert: boolean;
  image: {
    imageMob: string;
    imageDesk: string;
  };
}

const Item = ({ item }) => {
  const Icon = item.icon;
  return (
    <ListItem>
      {item?.icon && (
        <ListItemicon>
          <Icon />
        </ListItemicon>
      )}

      <ItemText>{item.title}</ItemText>
    </ListItem>
  );
};

export const MainWhatIs: FC<IProps> = ({ title, subTitle, items }) => {
  const [click, setClick] = useState(false);

  return (
    <Wrapper>
      <ContentWrapper>
        <Title>{title}</Title>
        {subTitle && <Description>{subTitle}</Description>}

        <ImageWrapper>
          {click ? (
            <iframe
              src="https://www.youtube.com/embed/X9F3eDN7jlA"
              title="YouTube video player"
              style={{
                borderRadius: '10px',
                border: 'none',
              }}
              className="video"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          ) : (
            <Image
              className="fasad-image"
              onClick={() => setClick(true)}
              src={fasad}
              alt="YouTube video player"
              style={{ cursor: 'pointer' }}
            />
          )}
        </ImageWrapper>

        <ListItems>
          {items.map((item, idx) => (
            <Item key={idx} item={item} />
          ))}
        </ListItems>
      </ContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Title = styled(TitleH2)`
  margin-bottom: 12px;
  text-align: center;
  @media only screen and ${devices.desktop} {
    margin-bottom: 16px;
  }
`;

const Description = styled(RegularText)`
  max-width: 744px;
  margin: 0 auto 24px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400px;
  @media only screen and ${devices.desktop} {
    font-size: 14px;
    margin-bottom: 40px;
  }
`;

const ImageWrapper = styled.div`
  margin-bottom: 16px;
  & img {
    border-radius: 10px;
  }
  & iframe {
    width: 100%;
    min-height: 210px;
    @media only screen and ${devices.desktop} {
      height: 420px;
    }
  }

  & .fasad-image {
    width: 100% !important;
    height: 211px;
    object-fit: cover;
    @media only screen and ${devices.desktop} {
      height: auto;
    }
  }
  @media only screen and ${devices.desktop} {
    margin-bottom: 40px;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  & button {
    width: 100%;
  }

  line-height: 0;
  @media only screen and ${devices.desktop} {
    display: flex;
    justify-content: center;
    flex-direction: column;

    & button {
      width: 168px;
    }
  }
`;

const ListItems = styled.ul`
  display: flex;
  align-items: start;
  flex-direction: column;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  row-gap: 8px;
  width: 100%;
  @media only screen and ${devices.desktop} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    row-gap: 16px;
    gap: 16px;
  }
`;

const ListItem = styled.li`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  border: 1px solid rgba(219, 224, 239, 1);
  padding: 16px;
  border-radius: 6px;
  min-height: 80px;
`;

const ListItemicon = styled.div`
  @media only screen and ${devices.desktop} {
    margin-bottom: 0px;
  }
`;

const ItemText = styled(RegularText)`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400px;
  @media only screen and ${devices.desktop} {
    font-size: 14px;
  }
`;
