import React, { FC, useCallback, useRef, useState, useEffect } from 'react';
import Image from 'next/image';
import styled from 'styled-components';
import { Autoplay, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import Button from '@/components/Button/Button';
import { useNewsletterModalContext } from '@/context/newsletter-modal.context';
import { ArrowIcon } from '@/public/icons';
import { devices } from '@/styles/breakpoints';
import { TitleH2 } from '@/styles/typography';
import { IRoutePath } from '@/types/router';
import Link from '@/components/Link/Link';

interface IProps {
  items: { title: string; tag: string }[];
  title: string;
  description: string;
}

const PotentialItem = ({ item }) => {
  return (
    <Item>
      <Image alt={item.title} src={item.image} />
      <ItemText>{item.description}</ItemText>
    </Item>
  );
};

const PotentialButton = ({ item, onClick, isActive }) => {
  return (
    <CustomButton onClick={onClick} $isactive={isActive}>
      {item.tag}
    </CustomButton>
  );
};

export const Potential: FC<IProps> = ({ items, title }) => {
  const sliderRef = useRef(null);
  const [active, setActive] = useState(0);
  const [totalSlides, setTotalSlides] = useState(0);

  const handleSlideChange = (index) => {
    if (sliderRef.current && sliderRef.current.swiper) {
      sliderRef.current.swiper.slideTo(index);
      setActive(index);
    }
  };

  useEffect(() => {
    if (sliderRef.current) {
      const swiper = sliderRef.current.swiper;
      setTotalSlides(swiper.slides.length);
      swiper.on('slideChange', () => {
        setActive(swiper.activeIndex);
      });
    }
  }, []);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current || active === 0) return;
    setActive((prev) => (prev -= 1));
    sliderRef.current.swiper.slidePrev();
  }, [active]);

  const handleNext = useCallback(() => {
    if (!sliderRef.current || active === totalSlides - 1) return;
    setActive((prev) => (prev += 1));
    sliderRef.current.swiper.slideNext();
  }, [active, totalSlides]);

  return (
    <Wrapper>
      <Title>{title}</Title>
      <ListItems>
        {items.map((item, idx) => (
          <PotentialButton
            onClick={() => handleSlideChange(idx)}
            $isactive={active === idx}
            item={item}
            key={idx}
          />
        ))}
      </ListItems>
      <SliderWrapper>
        <Swiper
          ref={sliderRef}
          slidesPerView={1}
          spaceBetween={24}
          navigation={true}
          effect="fade"
          loop={true}
          // autoplay={true}
          modules={[Autoplay, Navigation]}
          className="features-swiper"
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 1,
            },
            1024: {
              slidesPerView: 1,
            },
          }}
        >
          {items.map((item, idx) => (
            <SwiperSlide key={idx}>
              <ListItem key={idx}>
                <PotentialItem item={item} />
              </ListItem>
            </SwiperSlide>
          ))}
        </Swiper>
        <ButtonWrapperActions>
          <Icon onClick={handlePrev} $isprev={true}>
            <ArrowIcon color="#262626" />
          </Icon>

          <Icon onClick={handleNext}>
            <ArrowIcon color="#262626" />
          </Icon>
        </ButtonWrapperActions>
      </SliderWrapper>

      <ButtonWrapper>
        <Button fullWidth className="secondary">
          <Link href={IRoutePath.FEATURES}>More Tools</Link>
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div``;
const SliderWrapper = styled.div`
  position: relative;
  /* margin-bottom: 56px;

  @media only screen and ${devices.desktop} {
    margin-bottom: 32px;
  } */
`;
const Title = styled(TitleH2)`
  text-align: center;
  margin-bottom: 24px;
  @media only screen and ${devices.desktop} {
    margin-bottom: 40px;
  }
`;

const ListItems = styled.ul`
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
  margin-bottom: 24px;
  @media only screen and ${devices.desktop} {
    justify-content: space-between;
    margin-bottom: 40px;
  }
`;

const ListItem = styled.li`
  width: 100%;
  max-width: 100%;
`;

const ItemText = styled.div`
  font-family: var(--font-roboto);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(25, 45, 82, 1);
  text-align: center;

  padding-top: 32px;
  @media only screen and ${devices.desktop} {
    margin: 0 auto 40px;
    max-width: 740px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }
`;

const Item = styled.article`
  & img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    @media only screen and ${devices.largeDesktop} {
      height: auto;
    }
  }
`;

const CustomButton = styled(Button)`
  padding: 8px 16px;
  color: ${(props) => (props.$isactive ? 'white' : ' rgba(15, 87, 202, 1)')};
  background-color: ${(props) =>
    props.$isactive ? 'rgba(32, 111, 237, 1)' : 'transparent'};
  border-radius: 100px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  @media only screen and ${devices.desktop} {
    padding: 8px 14px;
  }
`;

const ButtonWrapper = styled.div`
  @media only screen and ${devices.desktop} {
    max-width: 168px;
    margin: 0 auto;
  }
`;

const Icon = styled.div<{ $isprev?: boolean }>`
  transition: transform 0.3s;
  transform: ${(p) => (p.$isprev ? 'rotate(90deg)' : 'rotate(-90deg)')};
  width: 40px;
  height: 40px;
  background-color: white;
  border: 1px solid #e6e8ec;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
`;

const ButtonWrapperActions = styled.div`
  display: none;
  @media only screen and ${devices.largeDesktop} {
    position: absolute;
    width: 109%;
    top: 200px;
    left: -50px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
